@page {
  size: auto;
  margin: 0mm;
  padding: 0;
  border: 1px solid black;
}

@media screen {
  #labels-to-print-1-1 {
   display: none;
  }
}
