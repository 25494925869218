.login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 166px);
  width: 100%;
}

.login-container {
  background-color: white;
  box-shadow: 0 3px 46px rgba(0, 0, 0, 0.23);
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 48px;
  text-align: left;
  max-width: 408px;
}

.login-container__title {
  font-family: "SF Pro Display Bold", sans-serif;
  font-size: 40px;
  line-height: 40px;
  margin-bottom: 24px;
  color: black;
}

.login-container__text-before {
  font-size: 16px;
  line-height: 16px;
  font-weight: normal;
  margin-bottom: 48px;
  color: rgb(112, 112, 112);
}

.login-container__form-field-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
}

.login-container__form-field-label {
  font-family: "SF Pro Display Bold", sans-serif;
  width: 100%;
  color: black;
  font-size: 16px;
  line-height: 16px;
  margin-bottom: 12px;
}

.login-container__form-field-input input {
  background-color: rgb(246, 246, 246);
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  width: 100%;
  height: 48px;
  padding: 12px;
  font-size: 16px;;
}

.login-container__form-field-submit {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: rgb(44, 61, 113);
  color: white;
  font-family: "SF Pro Display Bold", sans-serif;
  font-size: 16px;
  line-height: 16px;
  height: 48px;
  margin: 48px 0;
  cursor: pointer;
  border-radius: 8px;
}

.login-container__form-field-submit:hover {
  background-color: #222F57;
}

.login-container__text-after {
  font-size: 16px;
  line-height: 16px;
  font-weight: normal;
  color: rgb(112, 112, 112);
}

.login-container__text-after a {
  color: rgb(44, 61, 113);
}

.login-container__text-after a:hover {
  color: rgba(44, 61, 113, 0.6);
}

