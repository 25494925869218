@page {
  size: auto;
  margin: 0mm;
  padding: 0;
  border: 1px solid black;
}

@media screen {
  #reclamations-to-print {
   display: none;
  }
}
