/* Fonts */
@font-face {
  font-family: "SF Pro Display Bold";
  src: url("../fonts/SFProDisplay-Bold.eot");
  src: url("../fonts/SFProDisplay-Bold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/SFProDisplay-Bold.woff2") format("woff2"),
    url("../fonts/SFProDisplay-Bold.woff") format("woff"),
    url("../fonts/SFProDisplay-Bold.ttf") format("truetype"),
    url("../fonts/SFProDisplay-Bold.svg#SFProDisplay-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SF Pro Display Regular";
  src: url("../fonts/SFProDisplay-Regular.eot");
  src: url("../fonts/SFProDisplay-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/SFProDisplay-Regular.woff2") format("woff2"),
    url("../fonts/SFProDisplay-Regular.woff") format("woff"),
    url("../fonts/SFProDisplay-Regular.ttf") format("truetype"),
    url("../fonts/SFProDisplay-Regular.svg#SFProDisplay-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("../fonts/SFProDisplay-Semibold.eot");
  src: url("../fonts/SFProDisplay-Semibold.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/SFProDisplay-Semibold.woff2") format("woff2"),
    url("../fonts/SFProDisplay-Semibold.woff") format("woff"),
    url("../fonts/SFProDisplay-Semibold.ttf") format("truetype"),
    url("../fonts/SFProDisplay-Semibold.svg#SFProDisplay-Semibold")
      format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
/* General disables */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "SF Pro Display Regular", sans-serif;
  text-decoration: none;
  list-style: none;
  border: none;
}
/* General containers */
body {
  background-color: rgba(0, 0, 0, 0.05);
}

body #root .App .MuiBox-root.jss1 {
  margin-top: 64px;
  padding-top: 32px;
  padding-bottom: 0 !important;
  background-color: rgb(255, 255, 255);
}

.MuiBox-root {
  background: transparent;
}

.MuiBox-root.MuiBox-root-2 {
  background-color: transparent;
}

/* General spacing */

.mb-48 {
  margin-bottom: 48px !important;
}

/* General texts */

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

/* Notifications */

.Toastify__toast--success,
.Toastify__toast--error {
  margin-top: 80px !important;
}

.Toastify__toast--success {
  background: #2c7171 !important;
  background-color: #2c7171 !important;
}

.Toastify__toast--error {
  background: #712c2c !important;
  background-color: #712c2c !important;
}

.Toastify__toast-body {
  text-align: left;
}

.errorInput {
  border-color: #712c2c !important;
  border: 1px solid;
}

.errorText {
  color: #712c2c !important;
}

.noResults {
  font-family: "SF Pro Display Bold", sans-serif;
  font-size: 16px;
  line-height: 16px;
  color: black;
  margin: 0 0 48px 0;
  background-color: white !important;
  width: 100%;
  padding: 12px;
  height: 384px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

/* Animations */

@keyframes chevronStatus {
  100% {
    transform: rotate(180deg);
  }
}

/* Buttons */
.button {
  padding: 0 24px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-family: "SF Pro Display Bold", sans-serif;
  font-size: 16px;
  line-height: 16px;
  border-radius: 8px;
  cursor: pointer;
}

.button.safe {
  background-color: rgba(44, 113, 113, 1);
}

.button.safe:hover {
  background-color: rgb(34, 87, 87);
}

.button.neutral {
  background-color: rgb(44, 61, 113);
}

.button.neutral:hover {
  background-color: #222f57;
}

.button.unsafe {
  background-color: #712c2c !important;
}

.button.unsafe:hover {
  background-color: #501e1e !important;
}

.button:disabled {
  background: #dddddd;
  cursor: default;
}

.button.disabled:hover {
  background: #dddddd;
  cursor: default;
}

/* Scrollbars */

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
  background-color: rgb(44, 61, 113);
  outline: 1px solid #222f57;
}

/* Pagination */

.table-pagination {
  width: auto;
  margin: 48px auto 0 auto;
  display: flex;
  justify-content: center;
  color: rgba(0, 0, 0, 0.6);
  z-index: 2;
}

.table-pagination > div {
  font-family: "SF Pro Display Bold", sans-serif;
  margin: 0 12px;
  cursor: pointer;
}

.table-pagination > div:hover {
  color: rgba(44, 113, 113, 1);
}

.table-pagination .currentPage {
  color: rgb(44, 61, 113);
}

.table-pagination > div.pageNoClickable {
  cursor: default;
}

.table-pagination > div.pageNoClickable:hover {
  color: rgba(0, 0, 0, 0.6);
}

.arrow-prev svg {
  transform: rotate(90deg);
}

.arrow-next svg {
  transform: rotate(-90deg);
}

.arrow-prev,
.arrow-next {
  position: relative;
}

.chevron-hitpoint {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
}

.loading {
  position: absolute;
  top: 23vh !important;
  left: 0;
  background: transparent;
  width: 100% !important;
  height: calc(100vh - 347px) !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  --color: rgb(44, 61, 113);
  --size-mid: 6vmin;
  --size-dot: 1.5vmin;
  --size-bar: 0.4vmin;
  --size-square: 3vmin;

  display: block;
  position: relative;
  width: 50%;
  display: grid;
  place-items: center;
}

.loader::before,
.loader::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
}

.loader.spinner::before,
.loader.spinner::after {
  width: var(--size-dot);
  height: var(--size-dot);
  border-radius: 50%;
  background-color: var(--color);
}

.loader.spinner::before {
  top: calc(50% + 4vmin);
  animation: loader-8-1 0.8s cubic-bezier(0.06, 0.01, 0.49, 1.18) infinite;
}

.loader.spinner::after {
  opacity: 0;
  top: calc(50% - 2vmin);
  animation: loader-8-2 0.8s cubic-bezier(0.46, -0.1, 0.27, 1.07) 0.2s infinite;
}

@keyframes loader-8-1 {
  0%,
  55%,
  100% {
    opacity: 0;
  }

  0% {
    transform: scale(0.2);
  }

  22% {
    opacity: 1;
  }

  33%,
  55% {
    transform: scale(1) translateY(-6vmin);
  }
}

@keyframes loader-8-2 {
  0%,
  100% {
    opacity: 0;
  }

  33% {
    opacity: 0.3;
  }

  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(4);
  }
}

/* Textos genéricos de cabeceras */

.texts {
  width: 100%;
  text-align: left;
}

.texts-title {
  font-family: "SF Pro Display Bold", sans-serif;
  font-size: 40px;
  line-height: 40px;
  color: black;
  margin-bottom: 12px;
}

.texts-help {
  font-size: 16px;
  line-height: 16px;
  color: #707070;
  margin-bottom: 48px;
}

/* Textos info extra en cabecera sección */

.header-info__container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;

  margin-bottom: 48px;
}

.header-info__item {
  text-align: left;
}

.header-info__label {
  font-family: "SF Pro Display Bold", sans-serif;
  font-size: 16px;
  line-height: 19px;
  color: black;
  margin-bottom: 12px;
}

.header-info__text {
  font-size: 16px;
  line-height: 19px;
  color: #707070;
  margin-bottom: 24px;
}

/* Create Button */

.create button {
  float: right;
  height: 50px;
  color: white;
  background-color: rgb(44, 61, 113);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 24px;
  border-radius: 8px;
  font-family: "SF Pro Display Bold", sans-serif;
  color: white;
  font-size: 16px;
  line-height: 16px;
  cursor: pointer;
}

.create button:hover {
  background-color: #222f57;
}

/* Print button */

.print button {
  float: right;
  height: 50px;
  color: white;
  background-color: #2c7171 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 24px;
  border-radius: 8px;
  font-family: "SF Pro Display Bold", sans-serif;
  color: white;
  font-size: 16px;
  line-height: 16px;
  cursor: pointer;
}

.print button:hover {
  background-color: rgb(34, 87, 87);
}

/* Table styles */

.table-headers {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  padding: 24px 24px;
  border-bottom: 1px solid black;
}

.table-header {
  font-family: "SF Pro Display Bold", sans-serif;
  display: flex;
  justify-content: flex-start;
}

.table-header.expandible {
  padding-left: 34px;
}

.col-10 {
  width: 10%;
}

.col-11 {
  width: 11.25%;
}

.col-15 {
  width: 15%;
}

.col-17 {
  width: 17%;
}

.col-20 {
  width: 20%;
}

.col-25 {
  width: 25%;
}

.col-30 {
  width: 30%;
}

.col-35 {
  width: 35%;
}

.col-40 {
  width: 40%;
}

.col-45 {
  width: 45%;
}

.col-50 {
  width: 50%;
}

.col-55 {
  width: 55%;
}

.col-60 {
  width: 60%;
}

.col-65 {
  width: 65%;
}

.col-70 {
  width: 70%;
}
.col-75 {
  width: 75%;
}

.col-80 {
  width: 80%;
}

.col-85 {
  width: 85%;
}

.col-90 {
  width: 90%;
}

.col-95 {
  width: 95%;
}

.col-100 {
  width: 100%;
}

.table-container__right-ones {
  z-index: 999;
  display: flex;
  gap: 12px;
}

.table-row-container {
  width: 100%;
  background-color: white;
  min-height: 384px;
  z-index: 1;
}

.table-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: auto;
  padding: 0 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  z-index: 9;
}

.table-row-normal {
  width: 100%;
  display: flex;
  align-items: center;
}

.table-row.expandible {
  position: relative;
  display: flex;
  flex-direction: column;
}

.table-row.expandible .table-row-normal svg:first-child {
  cursor: pointer;
}

.table-row.expandible .table-row-normal svg.opened:first-child {
  animation: chevronStatus 0.2s forwards;
}

.table-row-expanded-info {
  width: 100%;
  flex-direction: column;
  display: none;
}

.table-row-expanded-info.opened {
  display: flex;
}

.table-row-expanded-info .table-row-expanded {
  display: flex;
  margin-bottom: 24px;
}

.table-row-expanded .table-cell {
  font-family: "SF Pro Display Bold", sans-serif;
  color: black;
}

.table-row.expandible svg:first-child {
  position: absolute;
  top: 22px;
  left: 20px;
}

.table-row:nth-child(odd) {
  background-color: rgb(245, 245, 245, 1);
}

.table-row:nth-child(even) {
  background-color: white;
}

.table-cell {
  color: rgba(0, 0, 0, 0.56);
  text-align: left;
  display: flex;
  justify-content: flex-start;
  gap: 12px;
  padding-right: 24px;
}

.table-cell.expandible {
  padding-left: 34px;
}

.table-cell button {
  margin-top: 8px;
  margin-bottom: 8px;
}

.cell-buttons {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.cell-buttons button {
  margin-right: 12px;
}

.MuiBox-root {
  padding-top: 0;
}

.MuiBox-root.MuiBox-root-2 {
  padding-top: 0;
}

.table-container {
  position: relative;
  width: 100%;
  max-width: 1300px;
  margin: auto;
  background-color: white;
  padding: 48px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: left;
  min-height: calc(100vh - 106px);
  background: white;
  z-index: 999;
  box-shadow: 0px 3px 46px rgba(0, 0, 0, 0.23);
}

.table-container__parameters {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  overflow: visible;
}

.table-container__left-ones {
  display: flex;
  justify-content: flex-start;
  overflow: visible;
}

.table-container__search-field {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.table-container__filters-fields {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}

.table-container__form-label {
  font-family: "SF Pro Display Bold", sans-serif;
  font-size: 16px;
  line-height: 16px;
  margin-bottom: 12px;
}

.table-container__form-input {
  position: relative;
}

.table-container__form-input svg {
  position: absolute;
  left: 12px;
  top: 14px;
}

.table-container__form-input input {
  width: 313px;
  height: 48px;
  background-color: #f6f6f6;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 24px;
  padding-left: 48px;
  margin-right: 24px;
  font-size: 16px;
}

.table-container__form-input button {
  height: 50px;
  border: 1px solid rgb(44, 61, 113);
  background-color: rgb(44, 61, 113);
  border-radius: 8px;
  padding: 0 24px;
  margin-right: 24px;
  color: white;
  font-family: "SF Pro Display Bold", sans-serif;
  font-size: 16px;
  line-height: 16px;
  cursor: pointer;
  margin-top: -2px;
}

.table-container__form-input button:hover {
  background-color: #222f57;
}

/* END table search */
/* START Table filters */

/* Prefix text */
.table__filter-text {
  margin: 0 12px 14px 12px;
}

.table__filter-container {
  position: relative;
  margin-right: 12px;
}

/* Filter collapsed */
/* container */
.table-container__filters-fields {
  position: relative;
  overflow: visible;
}

.table__filter-collapsed {
  position: relative;
  z-index: 99999;
}

.table__filter-collapsed input {
  position: relative;
  height: 48px;
  background-color: #f6f6f6;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  width: 200px;
  padding: 0 24px 0 12px;
  font-size: 16px;
}

.table__filter-collapsed svg {
  position: absolute;
  top: 20px;
  right: 14px;
}

.table__filter-date {
  height: 48px;
  background-color: #f6f6f6;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  width: 200px;
  padding: 0 24px 0 12px;
  font-size: 16px;
}

/* dropdown visible on hover */
.table__filter-container:hover > .table__filter-expanded {
  visibility: visible;
}

.table__filter-container:hover > .table__filter-collapsed svg {
  animation: chevronStatus 0.2s forwards;
}

/* Filter expanded */
.table__filter-expanded {
  visibility: hidden;
  position: absolute;
  top: 48px;
  left: 0;
  background-color: white;
  width: 200px;
  color: rgb(112, 112, 112);
  text-align: left;
  box-shadow: 0px 3px 46px rgba(0, 0, 0, 0.23);
  z-index: 9999;
}

.table__filter-expanded-container {
  position: relative;
  width: 200px;
  background-color: white;
  box-shadow: 0px 3px 46px rgba(0, 0, 0, 0.23);
}

/* Filter search */
.table__filter-search {
  position: absolute;
  top: 0;
  padding: 12px 0;
}

.table__filter-search input {
  position: absolute;
  top: 0;
  width: 200px;
  height: 48px;
  background-color: #f6f6f6;
  padding: 24px;
  padding-left: 48px;
  margin-right: 24px;
  font-size: 16px;
  outline: none;
}

.table__filter-search svg {
  position: absolute;
  top: 16px;
  right: -35px;
}

/* Filter options container */
.table__filter-option-container {
  position: absolute;
  top: 48px;
  width: 200px;
  max-height: 300px;
  overflow-y: scroll;
  background-color: white;
  box-shadow: 0px 3px 46px rgba(0, 0, 0, 0.23);
}

.table__filter-option,
.table__filter-option-default {
  padding: 12px;
  cursor: pointer;
}

.table__filter-option:hover,
.table__filter-option-default:hover,
.table__filter-option.current,
.table__filter-option-default.current {
  color: white;
  background-color: rgb(44, 61, 113);
}

/* Table */

.table-container__table-container {
  width: 100%;
  text-align: left;
  background: white;
  z-index: 999;
}

/* START orderBy */

.table-headers div svg {
  cursor: pointer;
}

.table-headers div svg:hover {
  fill: #2c7171;
}

.table-headers div:first-child svg {
  margin-left: 24px;
}

.table-headers div:last-child svg {
  margin-left: 12px;
  transform: rotate(180deg);
}

.orderBy-desc,
.orderBy-asc {
  position: relative;
}

.orderBy-hitpoint {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  cursor: pointer;
}
/* END orderBy */

/* START Modal */
.modal__background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 999;
}

.modal__body {
  background-color: white;
  border-radius: 8px;
  padding: 48px;
  box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.23);
  display: flex;
  flex-direction: column;
  width: auto;
  max-width: 70%;
  min-width: 40%;
}

.modal-body__headers {
  width: 100%;
  text-align: left;
  display: flex;
  flex-direction: column;
}

.modal-body-headers__title {
  font-family: "SF Pro Display Bold", sans-serif;
  font-size: 40px;
  line-height: 40px;
  width: 100%;
  color: black;
  margin-bottom: 24px;
}

.modal-body-headers__subtitle {
  font-size: 16px;
  line-height: 16px;
  width: 100%;
  color: #707070;
  margin-bottom: 48px;
}

.modal-body__info {
  display: flex;
  justify-content: space-between;
  gap: 48px;
  align-items: flex-start;
  padding-bottom: 48px;
}

.modal-body-info__image {
  height: 35%;
  width: 35%;
  max-height: 35%;
  max-width: 35%;
  box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.23);
}

.modal-body-info__image img {
  height: 100%;
  width: 100%;
  max-height: 340px;
  max-width: 340px;
}

.modal-body-info__image.col-50 {
  width: 50%;
  max-width: 50%;
  height: auto;
}

.modal-body-info__image img {
  height: 100%;
  width: 100%;
}

.modal-body-info__texts {
  width: 65%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.modal-body-info__texts.col-50 {
  width: 50%;
}

.modal-body-info-texts__title {
  font-family: "SF Pro Display Bold", sans-serif;
  font-size: 40px;
  line-height: 40px;
  text-align: left;
  width: 100%;
  color: black;
  margin-bottom: 48px;
}

.modal-body-info-texts__label,
.modal-body-info-texts__text {
  text-align: left;
  font-size: 16px;
  line-height: 16px;
  width: 100%;
}

.modal-body-info-texts__label {
  font-family: "SF Pro Display Bold", sans-serif;
  color: black;
  text-align: left;
  margin-bottom: 12px;
}

.modal-body-info-texts__text {
  color: #707070;
  margin-bottom: 24px;
}

.modal-body__buttons {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  gap: 12px;
}

.prendas-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.prendas-container .prenda-container,
.prenda-modal-headers {
  width: 100%;
  display: flex;
}

.prenda-modal-headers {
  margin-bottom: 12px;
}

.prenda-modal-headers .prenda-info__label,
.prenda-info {
  font-family: "SF Pro Display Bold";
  width: 30%;
  text-align: left;
  color: #222f57;
}

.prenda-info__value {
  width: 30%;
  text-align: left;
}

/* START Footer */

.makeStyles-root-4 {
  z-index: 99999;
}

/* END Footer */

.print a {
  color: #fff!important;
  font-weight: 600;
}
