/* General spacing */
.no-margin {
  margin: 0 !important;
}

.mt-24 {
  margin-top: 24px !important;
}

/* color */
.text-green {
  color: rgba(44, 113, 113, 1);
}

.text-blue {
  color: #222f57;
}

.text-red {
  color: #712c2c;
}
/* container */
.form,
.form-container {
  width: 100%;
}

.form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  gap: 12px;
}

.form__first-row,
.form__second-row,
.form__4col-row {
  justify-content: flex-start;
  gap: 5%;
}

.input-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.input-container.select,
.input-container.prendas {
  width: 100%;
  margin-bottom: 24px;
}

.form__first-row .input-container.select,
.form__first-row .input-container.prendas,
.form__second-row .input-container {
  width: 30%;
}

.form__4col-row .input-container.select,
.form__4col-row .input-container.prendas {
  width: 20%;
}

.form__first-row .avoidFragmentKey {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  gap: 5%;
}

.form__first-row .avoidFragmentKey .input-container.select {
  width: 30%;
}

.form__first-row .input-container.col-4 {
  width: 20%;
}

.input-container.fileUpload {
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 24px;
}

.input-container.fileUpload .input-container-left {
  flex-direction: column;
  justify-content: flex-end;
}

.input-container.fileUpload .input-container-right {
  flex-direction: column;
  justify-content: flex-end;
  box-shadow: 0px 3px 46px rgba(0, 0, 0, 0.23);
}

.input-container-right img {
  width: 100%;
  max-width: 150px;
  height: 100%;
  max-height: 150px;
}

/* label */
.input-label {
  font-family: "SF Pro Display Bold", sans-serif;
  font-size: 16px;
  line-height: 16px;
  margin-bottom: 12px;
  width: 100%;
  text-align: left;
  margin-bottom: 12px;
}

.input-container.fileUpload .input-label {
  margin-bottom: 12px;
}

.input-container-left {
  width: 65%;
  text-align: left;
}

.input-container-right {
  width: 30%;
}

.input-text {
  color: #707070;
}

.input-container-left > input[type="file"] {
  margin-top: 24px;
}

.errorInputImage{
  border-color: #712c2c !important;
  border: 6px solid #712c2c;
}

.input-container-left > input[type="file"].errorInput {
  border: 1px solid #712c2c !important;
}

.input-container-right {
  height: 150px;
  width: 150px;
  background-color: white;
  box-shadow: 0px 3px 46px rgba(0, 0, 0, 0.23);
}

/* text & textarea */
.input-container input[type="text"],
.input-container textarea {
  background-color: #f6f6f6;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 24px;
  padding-left: 12px;
  text-align: left;
  margin-bottom: 24px;
}

.input-container.select input[type="text"] {
  margin-bottom: 0;
}

.input-container input[type="text"] {
  height: 48px;
}

/* select */
.input-select-collapsed {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  z-index: 3;
}

.input-container .input-select-collapsed input[type="text"] {
  width: 100%;
  margin-right: 0;
}

.input-select-collapsed > svg {
  position: absolute;
  top: 20px;
  right: 24px;
}

.input-select-expanded {
  position: absolute;
  left: 0;
  top: 71px;
  visibility: hidden;
  width: 100%;
  z-index: 1;
}

.input-select-expanded__container {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.input-select-expanded__search {
  position: relative;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
}

.input-select-expanded__search svg {
  position: absolute;
  top: 16px;
  left: 12px;
  z-index: 2;
}

.input-select-expanded__search input[type="text"] {
  border-radius: 0;
  z-index: 2;
  border: none;
  font-size: 16px;
  line-height: 16px;
  padding-left: 48px;
}

.input-container.select:hover > .input-select-expanded {
  visibility: visible;
}

.input-container.select:hover > .input-select-collapsed svg {
  visibility: visible;
  animation: chevronStatus 0.2s forwards;
}

.input-select-expanded__option-container {
  position: absolute;
  width: 100%;
  height: 300px;
  top: 0;
  text-align: left;
  background: white;
  box-shadow: 0px 3px 46px rgba(0, 0, 0, 0.23);
  padding: 48px 0 0 0;
  overflow-y: scroll;
}

.input-select-expanded__option {
  padding: 12px;
  color: black;
  background: white;
  cursor: pointer;
}

.input-select-expanded__option:hover,
.input-select-expanded__option.current {
  color: white;
  background: rgb(44, 61, 113);
}

/* input type File */

input[type="file"] {
  background-color: #f6f6f6;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
}

input[type="file"]::-webkit-file-upload-button {
  background-color: white;
  font-family: "SF Pro Display Bold", sans-serif;
  border-radius: 8px 0 0 8px;
  border: none;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  padding: 12px;
  margin-right: 12px;
  height: 50px;
  cursor: pointer;
}

input[type="file"]::-webkit-file-upload-button:hover {
  background-color: rgb(44, 61, 113);
  color: white;
}

/* buttons */

.form-buttons {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
}
.form-container button {
  margin-right: 12px;
}

.button.unsafe.disabled,
.button.neutral.disabled,
.button.safe.disabled {
  background-color: #707070 !important;
  cursor: default;
}

.button.unsafe.disabled:hover {
  background-color: #707070 !important;
  cursor: default;
}

/* only info inputs self filled */
.input-label.onlyInfo {
  margin-left: 20px !important;
}

input.onlyInfo {
  background-color: white !important;
  border: hidden !important;
  outline: hidden !important;
  padding-left: 20px !important;
}

/* Variable row container */
.variable-rows-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
}

.variable-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.variable-rows-container .form-container button {
  margin-bottom: 24px;
  width: 100%;
}

.variable-rows-container
  .variable-row:not(:first-child)
  .input-select-expanded {
  top: 45px;
}

.variable-rows-container .variable-row:nth-child(1),
.form.form__first-row.bg-light-grey .input-container.select.col-4:nth-child(1),
.form.form__first-row .avoidFragmentKey:nth-child(1) {
  z-index: 3000;
}

.variable-rows-container .variable-row:nth-child(2),
.form.form__first-row.bg-light-grey .input-container.select.col-4:nth-child(2),
.form.form__first-row .avoidFragmentKey:nth-child(2) {
  z-index: 2900;
}

.variable-rows-container .variable-row:nth-child(3),
.form.form__first-row.bg-light-grey .input-container.select.col-4:nth-child(3),
.form.form__first-row .avoidFragmentKey:nth-child(3) {
  z-index: 2800;
}

.variable-rows-container .variable-row:nth-child(4),
.form.form__first-row.bg-light-grey .input-container.select.col-4:nth-child(4),
.form.form__first-row .avoidFragmentKey:nth-child(4) {
  z-index: 2700;
}

.variable-rows-container .variable-row:nth-child(5),
.form.form__first-row.bg-light-grey .input-container.select.col-4:nth-child(5),
.form.form__first-row .avoidFragmentKey:nth-child(5) {
  z-index: 2600;
}

.variable-rows-container .variable-row:nth-child(6),
.form.form__first-row.bg-light-grey .input-container.select.col-4:nth-child(6),
.form.form__first-row .avoidFragmentKey:nth-child(6) {
  z-index: 2500;
}

.variable-rows-container .variable-row:nth-child(7),
.form.form__first-row.bg-light-grey .input-container.select.col-4:nth-child(7),
.form.form__first-row .avoidFragmentKey:nth-child(7) {
  z-index: 2400;
}

.variable-rows-container .variable-row:nth-child(8),
.form.form__first-row.bg-light-grey .input-container.select.col-4:nth-child(8),
.form.form__first-row .avoidFragmentKey:nth-child(8) {
  z-index: 2300;
}

.variable-rows-container .variable-row:nth-child(9),
.form.form__first-row .avoidFragmentKey:nth-child(9) {
  z-index: 2200;
}

.variable-rows-container .variable-row:nth-child(10),
.form.form__first-row .avoidFragmentKey:nth-child(10) {
  z-index: 2100;
}

.variable-rows-container .variable-row:nth-child(11),
.form.form__first-row .avoidFragmentKey:nth-child(11) {
  z-index: 2000;
}

.variable-rows-container .variable-row:nth-child(12),
.form.form__first-row .avoidFragmentKey:nth-child(12) {
  z-index: 1900;
}

.variable-rows-container .variable-row:nth-child(13),
.form.form__first-row .avoidFragmentKey:nth-child(13) {
  z-index: 1800;
}

.variable-rows-container .variable-row:nth-child(14),
.form.form__first-row .avoidFragmentKey:nth-child(14) {
  z-index: 1700;
}

.variable-rows-container .variable-row:nth-child(15),
.form.form__first-row .avoidFragmentKey:nth-child(15) {
  z-index: 1600;
}

.variable-rows-container .variable-row:nth-child(16),
.form.form__first-row .avoidFragmentKey:nth-child(16) {
  z-index: 1500;
}

.variable-rows-container .variable-row:nth-child(17),
.form.form__first-row .avoidFragmentKey:nth-child(17) {
  z-index: 1400;
}

.variable-rows-container .variable-row:nth-child(18),
.form.form__first-row .avoidFragmentKey:nth-child(18) {
  z-index: 1300;
}

.variable-rows-container .variable-row:nth-child(19),
.form.form__first-row .avoidFragmentKey:nth-child(19) {
  z-index: 1200;
}

.variable-rows-container .variable-row:nth-child(20),
.form.form__first-row .avoidFragmentKey:nth-child(20) {
  z-index: 1100;
}

.variable-rows-container .variable-row:nth-child(21),
.form.form__first-row .avoidFragmentKey:nth-child(21) {
  z-index: 1000;
}

.variable-rows-container .variable-row:nth-child(22),
.form.form__first-row .avoidFragmentKey:nth-child(22) {
  z-index: 900;
}

.variable-rows-container .variable-row:nth-child(23),
.form.form__first-row .avoidFragmentKey:nth-child(23) {
  z-index: 800;
}

.variable-rows-container .variable-row:nth-child(24),
.form.form__first-row .avoidFragmentKey:nth-child(24) {
  z-index: 700;
}

.variable-rows-container .variable-row:nth-child(25),
.form.form__first-row .avoidFragmentKey:nth-child(25) {
  z-index: 600;
}

.variable-rows-container .variable-row:nth-child(26),
.form.form__first-row .avoidFragmentKey:nth-child(26) {
  z-index: 500;
}

.variable-rows-container .variable-row:nth-child(27),
.form.form__first-row .avoidFragmentKey:nth-child(27) {
  z-index: 400;
}

.variable-rows-container .variable-row:nth-child(28),
.form.form__first-row .avoidFragmentKey:nth-child(28) {
  z-index: 300;
}

.variable-rows-container .variable-row:nth-child(29),
.form.form__first-row .avoidFragmentKey:nth-child(29) {
  z-index: 200;
}

.variable-rows-container .variable-row:nth-child(30),
.form.form__first-row .avoidFragmentKey:nth-child(30) {
  z-index: 100;
}

/* Order imported values to assign */

.bg-light-grey,
.bg-grey {
  padding-top: 24px;
  padding-left: 60px;
}

.bg-grey {
  background-color: #ddd;
}

.bg-light-grey {
  background-color: #eee;
  z-index: 99999;
}

/* Order imported previsualization */
.order-preview__title,
.order-preview__headers,
.order-preview__headers-garments {
  width: 100%;
  text-align: left;
  margin-bottom: 24px;
}

.order-preview__headers,
.order-preview__container-row,
.order-preview__headers-garments {
  display: flex;
  justify-content: flex-start;
  padding: 24px;
}

.order-preview__headers-garments {
  margin-bottom: 0;
}

.order-preview__headers {
  border-bottom: 1px solid black;
}

.order-preview__container-row,
.order-preview__headers-garments {
  border-bottom: 1px solid #ddd;
}

.order-preview__headers p,
.order-preview__headers-garments p {
  font-family: "SF Pro Display Bold";
}

.order-preview__headers-garments p {
  color: rgba(44, 113, 113, 1);
}

.order-preview__container,
.order-preview__container-row {
  width: 100%;
}

.order-preview__container-row {
  padding: 0px 24px;
}

.order-preview__container-cell {
  text-align: left;
  padding-bottom: 24px;
}

.order-preview__garment-cells-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding: 24px;
}

.order-preview__garment-cells-container p {
  text-align: left;
}

.form.form__first-row .order-preview__garment-cells-container:nth-child(odd){
  background-color: rgb(245, 245, 245, 1);
}

.form.form__first-row .order-preview__garment-cells-container:nth-child(even){
  background-color: rgb(252, 252, 252, 1);
}

/* Animations */

@keyframes chevronStatus {
  100% {
    transform: rotate(180deg);
  }
}
